import React, {useRef, useState,useEffect } from "react"
import {Link, StaticQuery, graphql } from "gatsby"
import Cookies from 'universal-cookie';
import MenuItem from "./MenuItem"
import MobileMenu from "./MobileMenu"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

/**
 * Define MenuItem fragment and get all primary menu items.
 */

const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETMAINMENU {
        wpgraphql {
            menuItems(where: {location: SECONDARY_MENU}) {
                nodes {
                    ...MenuItem
                    childItems {
                      nodes {
                        ...MenuItem
                        childItems {
                          nodes {
                            ...MenuItem
                            childItems {
                              nodes {
                                ...MenuItem
                                childItems {
                                  nodes {
                                    ...MenuItem
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                }
            }
            generalSettings {
                url
            }
        }
    }
`


const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(process.env.WORDPRESS_URL, ``)
}

const RenderMenuItem = menuItem => {
  const extension=menuItem.url.split('.').pop();
  const link = createLocalLink(menuItem.url)
  const classLabel=menuItem.label;

  //  console.log(menuItem.label + ' ' + classLabel);

  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    //number of children under personal savings etc
    return RenderSubMenu(menuItem)
  } else {
    //console.log(menuItem.url+extension)
    if(extension!="pdf"){
      const link = createLocalLink(menuItem.url)
      /* conditionally add a class of 'cta-button' */
      /*
      if(classLabel === 'Apply for Account' || classLabel === 'Broker Portal' || classLabel.toLowerCase() === 'puma for intermediaries login') {
        return (
            <li key={menuItem.id} className="cta-button"><Link to={link} target="_blank"><span>{menuItem.label}</span></Link></li>
          )
      } else {
        return (
          <li key={menuItem.id} className="tier-2"><Link to={link}><span>{menuItem.label}</span></Link></li>
        )
      }
      */
      if(classLabel === 'Apply for Account' || classLabel === 'Broker Portal' || classLabel.toLowerCase() === 'puma for intermediaries login') {
        return (
            <li key={menuItem.id} className="cta-button"><Link to={link} target="_blank"><span>{menuItem.label}</span></Link></li>
          )
      } else {
        if(classLabel === 'Register for our Upgraded Savings Portal') {
          return (
            <span className="cta-wrapper">
              <p>{menuItem?.label}</p>
              <Link to={link}><span>Register</span></Link>
            </span>
          )
        } else {
          return (
            <li key={menuItem.id} className="tier-2"><Link to={link}><span>{menuItem.label}</span></Link></li>
          )
        }
      }
    }
    else {
      const link = createLocalLink(menuItem.url)
      return (
        <li
          key={menuItem.id}
          className="tier-2"
        >
          <a href={link}><span>{menuItem.label}</span></a>
        </li>
      )
    }
  }
}

const cookies = new Cookies();
const homeid=""

function mainSelected(classLabel){
  cookies.set('page', classLabel, { path: '/' });
  //console.log("COOKIE"+cookies.get('page'));
  //setPageClassState(classLabel)
}
function subSelected(classLabel){
  cookies.set('subpage', classLabel, { path: '/' });
  //console.log("COOKIESUB"+cookies.get('subpage'));
  //setPageClassState(classLabel)
}

const RenderSubMenu = menuItem => {
  const ref = useRef(null);

  const [hoverRef, isHovered] = useHover();
  const [hovered, setHovered] = useState(false);
  const [hoveredSub, setHoveredSub] = useState(false);
  const [mainClicked, setMainClicked] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const toggleSubHover = () => setHoveredSub(!hoveredSub);
  const webpageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [path, setPath] = useState(webpageUrl)
  const link = createLocalLink(menuItem.url)
  const classLabel=menuItem.label
  const classId=menuItem.id
  //  const classId=""
  const isCurrent=""
  const urlList=[]
  const urlSubList=[]

  // console.log(classLabel + ' ' + classId);

  function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);


    useEffect(() => {

        const node = ref.current;

        if (node) {
          node.addEventListener('mouseover', handleMouseOver);
          node.addEventListener('mouseout', handleMouseOut);

          return () => {
            node.removeEventListener('mouseover', handleMouseOver);
            node.removeEventListener('mouseout', handleMouseOut);
          };
        }
      },
      [ref.current] // Recall only if ref changes
    );

    return [ref, value];
  }
  function mouseEnter() {
    const classId="main dHover"
  }
  function mouseLeave() {
    console.log('mouse leave')
    const classId="main"
  }

  if (classLabel==="Personal"  ||  classLabel==="Business"  ||  classLabel==="Intermediaries") {
    //console.log(path)
    const classId="main"
    const isCurrent=""
    urlList.push(menuItem.url.replace(/https?:\/\/[^\/]+/i, ""))
    //set  is-current if on the page
    //if(classLabel==cookies.get('page')){isCurrent="is-current";}

      menuItem.childItems.nodes.forEach(function(content) {
        var pageURL=content.url.replace(/https?:\/\/[^\/]+/i, "");
        urlList.push(pageURL)
        //console.log(content.url)
        content.childItems.nodes.forEach(function(subContent) {
          if(subContent.url!=="#"){
            var mainSubPageURL=subContent.url.replace(/https?:\/\/[^\/]+/i, "");
            //console.log("sub:"+subContent.url)
            urlList.push(mainSubPageURL)
          }
          subContent.childItems.nodes.forEach(function(subSubContent) {
          //console.log(subSubContent);
          var subPageURL=subSubContent.url.replace(/https?:\/\/[^\/]+/i, "");
          var splitUrl=subPageURL.split("/")
          //not add pages under root
          if(splitUrl.length>3){
            urlList.push(subPageURL)
          }
          //var subPageURL=subContent.url.replace(/https?:\/\/[^\/]+/i, "");
          //urlList.push(subPageURL)
        })
        })
      })

      var n = urlList.includes(path);

    return (
      <li
        key={menuItem.label}
        // className={`main ${hovered ? "dHover" : ""} ${classLabel==cookies.get('page') ? "is-current" : ""}`}
        // className={`${hovered ? 'main dHover' : 'main'}`}
        // className={menuItem.label === 'Intermediaries' ? 'main dHover' : 'main'}
        className={'main'}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        //onClick={() => mainSelected(classLabel)}
      >
        <Link to={link}>{menuItem.label}</Link>
        <div className="menu-secondary-menu-container">
          <ul id="menu-secondary-menu" className="menu">
            {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
          </ul>
          </div>
      </li>
    )
  }
  else if(classLabel==="Asset Finance" || classLabel==="Personal Savings"  ||  classLabel==="Business Savings"  ||  classLabel==="Specialist Car Finance" ||  classLabel==="Specialist Mortgages" ||  classLabel==="Development Finance" ||  classLabel==="Business Savings" ){

    urlSubList.push(menuItem.url.replace(/https?:\/\/[^\/]+/i, ""))
    //set  is-current if on the page
    //if(classLabel==cookies.get('page')){isCurrent="is-current";}

      menuItem.childItems.nodes.forEach(function(childContent) {
        var childPageURL=childContent.url.replace(/https?:\/\/[^\/]+/i, "");
        urlSubList.push(childPageURL)
        //console.log(childPageURL)
        childContent.childItems.nodes.forEach(function(childSubContent) {
          //console.log("LENGTH"+childSubContent.url)
          var childSubPageURL=childSubContent.url.replace(/https?:\/\/[^\/]+/i, "");
          urlSubList.push(childSubPageURL)
        })
      })

        //console.log("sub sub pages"+urlSubList)
        var p = urlSubList.includes(path);
    return (
      <li
        key={menuItem.label}
        className={'desk-tier-0'}
        ref={hoverRef}
      >
      {link
        ? <Link to={link} activeClassName="active parent-active" className={` ${p ? "parent-active" : ""}`}><span>{menuItem.label}</span></Link>
        : <span className="menu-title-tier-1">{menuItem.label}</span>
      }
        <div className="sub-menu-wrap">
        <ul className="sub-menu">
          {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
        </ul>
        </div>
      </li>
    )
  }
  /* check if label is span row */
  else if(classLabel==="Span Row") {
    return(
      <li
        key={menuItem.label}
        className="span-row"
      >
      {link
        ? <Link to={link} activeClassName="active parent-active" className={`${p ? "parent-active" : ""} ${headerClass}`}><span>{menuItem.label}</span></Link>
        : <span className="menu-title-tier-1">{menuItem.label}</span>
      }

        <div className="sub-menu-wrap">
          <ul className="span-row" id="span-row">
            {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
          </ul>
        </div>
      </li>
    )
  }
  else{
  //SME Savings AND Charity savings
  if(classLabel==="bmF2X21lbnVfaXRlbTo1NTY2" ||  classLabel==="bmF2X21lbnVfaXRlbTo1NTc0"   ){
    var headerClass="menu-title-tier-1";
  }
  else{
    var headerClass="";
  }

  return (

    <li
      key={menuItem.label}
      className="desk-tier-1"
    >

    {link
      ? <Link to={link} activeClassName="active parent-active" className={`${p ? "parent-active" : ""} ${headerClass}`}><span>{menuItem.label}</span></Link>
      : <span className="menu-title-tier-1">{menuItem.label}</span>
    }
      <div className="sub-menu-wrap">
        <ul className="sub-menu">
          {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
        </ul>
      </div>
    </li>
  )
}

}

const Menu = () => {
  const [clicked, setClicked] = useState(false);
  const [mobClicked, setMobClicked] = useState(false);
  const [mobAboutClicked, setAboutMobClicked] = useState(false);
  const [mobCareersClicked, setCareersMobClicked] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [popScroll, setpopScroll] = useState(true)
  const [removeTemp, setRemoveTemp] = useState(false)
  const [searchShow, setSearchShow] = useState(false)
  const toggleSearch = () => setSearchShow(!searchShow);

  const about="about"
  const careers="careers"
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const savingsBanner=""


  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y
    //if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    if(currPos.y>-1) {
      setHideOnScroll(true)
      setpopScroll(true)
      setRemoveTemp(false)
      //console.log("home"+currPos.y)
    }
    else if(currPos.y<-800) {
      setpopScroll(false)
      if(url=="/personal-savings/" || url=="/business/savings/" || url=="/business/development-finance-business/" || url=="/business/specialist-business-finance/" || url=="/intermediaries/specialist-business-finance-intermediaries/" || url=="/intermediaries/specialist-mortgages/" || url=="/intermediaries/specialist-mortgages/products/specialist-buy-to-let/" || url=="/intermediaries/specialist-mortgages/products/residential-commercial-bridging/" || url=="/business/savings/sme-savings-accounts/" || url=="/business/savings/charity-savings/"){
        setRemoveTemp(true)
        //console.log("HELLO"+removeTemp)
      }

      //console.log("after"+currPos.y)
    }
    else{
      setHideOnScroll(false)
      setpopScroll(true)

    }

  }, [hideOnScroll])

  function openSearch(index) {
    setSearchShow(true);

  }
  function openDetails(index) {
    setClicked(clicked === index ? -1 : index);
  }
  function closeDetails(index) {
    setClicked(clicked === "");
  }
  function openMobile(index) {
    setMobClicked(!mobClicked);
  }
  function openAboutMobileSub() {
    setAboutMobClicked(!mobAboutClicked);
  }
  function openCareersMobileSub() {
    setCareersMobClicked(!mobCareersClicked);
  }

  useEffect(() => {
    // Run! Like go get some data from an API.
    //console.log("HHOORAG"+path);
    //console.log(updateLoad)
  }, []);

  return (
    <StaticQuery
      query={MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
            <>
            <div className="main-header hide-on-responsive" >
              <div className="full-width-flex-container">
                <div className="header-sub-links-container">
                </div>

                <div className={`${clicked ? 'header-top-menu-holder active' : 'header-top-menu-holder'}`} >
                <nav>
                  <div className="menu-top-menu-container">
                  <ul id="menu-top-menu" className="menu">
                    <li id="menu-item-3161" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3161"><a href="/about-us/">About us</a></li>
                    <li id="menu-item-74" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-74"><a href="/careers/">Careers</a></li>
<li id="menu-item-3163" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3163"><a href="/news-and-insights/">News</a></li>
                    <li id="menu-item-3190" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3190"><a href="/about-us/contact/">Contact us</a></li>
                  </ul>
                  </div>
                    <ul>
                      <li>
                      <a href="/faqs/">FAQs</a>
                      </li>
                    </ul>
                  </nav>
                  <span className="close" onClick={() => closeDetails(1)}></span>
                  </div>
                  <div className="header-search-link-holder">
                    <span></span>
                  </div>
                  </div>

                  <div className="trigger-sticky"></div>
                    <div className={`sticky-spacer  ${hideOnScroll ? '' : 'is-sticky'}`}></div>


                           <div className={`full-width-flex-container white-background sticky-init ${hideOnScroll ? '' : 'is-sticky '}${removeTemp ? 'removeTemp' : ''}`}>
                              <div className="restrict-width">
                                 <div className="header-logo-container" onClick={() => mainSelected(homeid)}>
                                                      <Link to="/">
                                       <img src="/htbcontent/themes/htb-personal/dist/assets/img/logo_htb.svg?v=2" alt="Hampshire Trust Bank (HTB)" title="Hampshire Trust Bank (HTB)" width="141px" height="50px"></img>
                                    </Link>
                                  </div>

                                 <div className="header-secondary-menu-holder clearfix">
                                    <nav>
                                    <ul id="menu-primary-menu">
                                      {data.wpgraphql.menuItems.nodes.map(menuItem => {
                                        if (menuItem.childItems.nodes.length) {
                                          return RenderSubMenu(menuItem)
                                        } else {
                                          return RenderMenuItem(menuItem)
                                        }
                                      })}

        </ul>
        </nav>
        </div>
        <div className="header-search-container">
        <div className="header-search-icon-holder" onClick={toggleSearch}>
                    <svg version="1.1" id="search-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 22 22"
                    style={{
                      enableBackground:
                        "new 0 0 22 22",
                    }}
                    width="22" height="22">
                       <g>
                          <circle className="search" cx="9.84" cy="9.51" r="7" />
                          <line className="search" x1="15" y1="15" x2="22" y2="22" />
                       </g>
                    </svg>
                 </div>
                <div className="global-nav-toggle" onClick={() => openDetails(1)}>
              <span className="toggle-global-nav"></span>
           </div>
        </div>
        </div>
        </div>
        <div className={`header-search-dropdown-container  ${searchShow ? 'is-active' : ''}`}>
            <div className="header-search-dropdown-holder">
               <form className="" method="">
                  <div className="header-search-field-holder">
                     <input type="text" className="st-default-search-input" name="s" id="search" placeholder="Type in product or service"/>
                  </div>
                  <div className="header-search-submit-holder">
                     <input type="submit" value="Search"/>
                  </div>
               </form>
               <div className="st-search-container"></div>
            </div>
        </div>
        </div>
        <div className={`${mobClicked ? 'mobile-header show-on-responsive mobile-trigger-sticky is-active' : 'mobile-header show-on-responsive mobile-trigger-sticky'}`}>

        <div className="mobile-sticky-spacer"></div>

         <div className="full-width-flex-container mobile-sticky-init">
            <div className="header-logo-container">
                <Link to="/">
                  <img src="/htbcontent/themes/htb-personal/dist/assets/img/logo_htb.svg?v=2" alt="Hampshire Trust Bank (HTB)" title="Hampshire Trust Bank (HTB)" width="99px" height="35px"/>
               </Link>
            </div>

            <div className="mobile-login-search-holder">
               <ul>
                  <li>
                    <a href="/welcome-to-our-online-portal/">
                        Log in
                        <span className="mob-login-icon"></span>
                     </a>
                  </li>
               </ul>
            </div>

            <div className="mobile-burger-outer-container">
              <div className={`${mobClicked ? 'burger-holder is-active' : 'burger-holder'}`} id="hamburger-6" onClick={() => openMobile(1)}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </div>

        </div>
        <div className="mobile-header-sub-links-container">
            <nav>
              <ul>
              </ul>
            </nav>
         </div>
         <div className={`${mobClicked ? 'full-width-menu-container active' : 'full-width-menu-container'}`} style={{display: mobClicked ? 'block' : 'none'}}>
            <div className="mobile-main-menu-holder clearfix">
              <nav>
                <div className="menu-top-menu-container">
                  <ul id="menu-top-menu-1" className="menu">
                    <MobileMenu />
                  </ul>
                </div>
              </nav>
            </div>
            <div className="mobile-divisions-menu-holder">
          </div>
          <div className="spacer-mob-fix"></div>
         </div>
        </div>
        </>
          )
        }
        return null
      }}
    />
  )
}

export default Menu
